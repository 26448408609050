import axios from 'axios'
import router from "@/router";
import globalData from "../global";
import {Notification} from 'element-ui';

const request = axios.create({
  // 统一设置URL前缀
  baseURL: `${globalData.serverIp}`,
  // 请求最长响应时间
  timeout: 10000,
})

// 请求拦截器
request.interceptors.request.use(config => {
  config.headers['Content-Type'] = config.contextType?config.contextType:'application/json';
  config.headers['token'] = localStorage.getItem("token");
  return config
}, error => {
  return Promise.reject(error)
});

// 响应拦截器
request.interceptors.response.use(response => {
  let res = response.data
  // 判断状态码 错误则给出提醒
  if (res.status !== 1) {
    // 显示错误信息
    Notification({type: 'error', title: '操作失败', message: res.msg})
    if (res.status === -2) {
      // 如果登录信息过期 重置本地存储 前往登录
      localStorage.removeItem("token")
      router.replace("/login").then(r => {})
    }
  }
  // 返回结果
  return res
}, error => {
  if (error.code === 'ECONNABORTED' || error.message.includes("timeout")) {
    Notification({type: 'error', title: '请求超时了', message: '当前网络环境较差，请稍后重试'})
  } else if (error.message === "Network Error") {
    Notification({type: 'error', title: '网络出错了', message: '当前网络环境出错，请刷新重试'})
  } else {
    Notification({type: 'error', title: '出错了', message: error.message})
  }
  return Promise.reject(error)
});

export default request;