<template>
  <router-view/>
</template>

<script>
import system from "@/api/system";

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

export default {
  name: 'App',
  data() {
    return {

    }
  },

  created() {
    this.fn()
    document.title = process.env.VUE_APP_NAME
    system.refreshToken().then(res => {
      if (res.status === 1) {
        localStorage.setItem('token', res.data[0].token)
      }
    })
  },

  methods: {
    fn () {
      let timer = setInterval(()=>{
        let token = localStorage.getItem('token')
        if (token && parseInt(Date.now()/1000) - JSON.parse(Base64.decode(token)).time > 60 * 60) {
          system.refreshToken().then(res => {
            if (res.status === 1) {
              localStorage.setItem('token', res.data[0].token)
            }
          })
        }
      },60000 * 10);
      this.$once('hook:beforeDestroy',()=>{
        clearInterval(timer);
        timer = null;
      })
    }
  }

};
</script>

<style>
/*解决弹窗导致的错位问题*/
body {
  padding-right: 0 !important;
}

.v-application ul, .v-application ol {
  padding-left: 0 !important;
}

.v-application span.title {
  font-size: 1rem !important;
}

</style>
