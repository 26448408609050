export default {
  serverIp: process.env.VUE_APP_BASE_URL,
  websiteUrl: process.env.VUE_APP_WEBSITE_URL,
  admUrl: process.env.VUE_APP_ADM_URL,

  modelList: {
    about: {
      value: 1,
      pathWithId: '/about/list/edit/'
    }, system: {
      value: 2,
      name: '网站配置',
      path: '/system/config'
    }, news: {
      value: 5,
      name: '企业资讯专题',
      path: '/news/subject',
      pathWithId: '/news/list/edit/'
    }, product: {
      value: 6,
      name: '全部产品专题',
      path: '/product/subject',
      pathWithId: '/product/list/edit/'
    }, productSeries: {
      value: 7,
      pathWithId: '/product/series/edit/'
    }, banner: {
      value: 8,
      path: '/home/banner'
    }, support: {
      value: 9,
      pathWithId: '/support/list/edit/',
    }, supportSeries: {
      value: 10,
      pathWithId: '/support/category/edit/'
    }, useCase: {
      value: 11,
      name: '应用案例专题',
      path: '/case/subject',
      pathWithId: '/case/list/edit/'
    }
  }
}
